<template>
  <div class="rego-popup" v-if="isShowRegoPopup">
    <div class="rego-popup-content">
      <h3>Update your rego</h3>
      <p class="font-weight-thin">
        We noticed that you didn't provide a registration number. Please enter
        your {{ isShowStateField ? "'state' and " : ""}} 'registration number' if you have one.
      </p>
      <p class="font-weight-thin">
        If your car is unregistered click 'Skip rego' to continue.
      </p>
      <div class="rego-popup-form mt-4">
        <div class="state-input" v-if="isShowStateField">
          <p class="font-weight-regular mb-2">State</p>
          <custom-select
            label=""
            v-model="regoPopupInfo.state"
            :options="states"
            :error="$v.regoPopupInfo.state.$error"
            helperText="Please select state"
            :class="{
              error: $v.regoPopupInfo.state.$error,
              'shadow-none': true,
            }"
          />
        </div>
        <div class="rego-input">
          <p class="font-weight-regular mb-2">Rego</p>
          <float-label label="">
            <b-input
              v-model.trim="regoPopupInfo.regoNumber"
              placeholder="Enter rego"
              autocomplete="off"
              required
              @keyup.enter="submit"
              :class="{
                error: $v.regoPopupInfo.regoNumber.$error,
                'shadow-none': true,
              }"
            />
          </float-label>
          <p
            v-if="
              $v.regoPopupInfo.regoNumber.$dirty &&
              !$v.regoPopupInfo.regoNumber.required
            "
            class="msg-error mb-0"
          >
            <i>Please enter rego number</i>
          </p>
          <p
            v-else-if="
              $v.regoPopupInfo.regoNumber.$dirty &&
              !$v.regoPopupInfo.regoNumber.alphaNum
            "
            class="msg-error mb-0"
          >
            <i>Please enter only alphabet characters and numerics</i>
          </p>
          <p
            v-else-if="
              $v.regoPopupInfo.regoNumber.$dirty &&
              !$v.regoPopupInfo.regoNumber.maxLength
            "
            class="msg-error mb-0"
          >
            <i>Please enter 1 to 9 characters!</i>
          </p>
        </div>
      </div>
      <div class="rego-popup-buttons mt-4">
        <button class="rego-popup-skip" @click="handleSkipRego">Skip rego</button>
        <button class="rego-popup-update" @click="handleUpdateRego" :class="updateButtonDisable ? 'disabled' : ''">
          <b class="font-weight-regular" v-show="!isLoadingApi">Update</b>
          <img
            v-show="isLoadingApi"
            class="input-spinner"
            src="../../assets/images/white-input-spinner.gif"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { required, maxLength, alphaNum } from "vuelidate/lib/validators";

export default {
  name: "RegoPopup",
  data() {
    return {
      regoPopupInfo: {
        state: "",
        regoNumber: "",
      },
    };
  },
  computed: {
    states: function () {
      return ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
    },
    updateButtonDisable: function() {
      return this.$v.regoPopupInfo.$invalid;
    },
  },
  props: {
    isShowRegoPopup: {
      type: Boolean,
      required: true,
    },
    isLoadingApi: {
      type: Boolean,
      required: true,
    },
    isShowStateField: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleSkipRego() {
      this.$emit('skipRego');
    },
    handleUpdateRego() {
      this.$v.regoPopupInfo.$touch();
      if (!this.$v.regoPopupInfo.$invalid) {
        this.$emit('updateRego', this.regoPopupInfo);
      }
    },
  },
  validations: {
    regoPopupInfo: {
      regoNumber: {
        required,
        alphaNum,
        maxLength: maxLength(9),
      },
      state: {
        required: function() {
          return !this.isShowStateField || this.regoPopupInfo.state !== ''
        }
      },
    },
  },
  components: {
    CustomSelect: () => import("../../components/common/CustomSelect"),
    FloatLabel: () => import("../../components/common/FloatLabel.vue"),
  },
};
</script>
<style lang="scss">
$error_color: #c1272d;

.rego-popup {
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #344054, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .rego-popup-content {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    max-width: 372px;
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
    .rego-popup-form {
      display: flex;
      gap: 20px;
      .state-input {
        min-width: 136px;
      }
      .rego-input {
        flex: 1;
        .border-lable-flt {
          margin-bottom: 0 !important;
        }
      }
      .form-control {
        border-radius: 5px !important;
        &.select-custom {
          background-position-x: 92% !important;
        }
      }
      input.error {
        caret-color: $error_color;
        border-color: $error_color !important;
      }
      select.form-control.invalid {
        border-color: $error_color !important;
      }
      .msg-error, .err-text {
        font-size: 12px;
        color: $error_color;
        text-align: right;
        white-space: nowrap;
        line-height: 20px !important;
        margin-bottom: 0 !important;
      }
    }
    .rego-popup-buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
      .rego-popup-skip, .rego-popup-update {
        flex: 1;
        font-family: Avenir Next LT W05 Regular !important;
        font-weight: 600 !important;
        height: 44px;
        outline: none !important;
        border: none;
        position: relative;
      }
      .rego-popup-skip {
        background-color: #fff;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
      .rego-popup-update {
        background-color: var(--primary-color);
        color: #fff;
        &.disabled {
          background-color: #CACACA !important;
          cursor: default;
        }
      }
    }
    .input-spinner {
      right: 50% !important;
      transform: translate(50%, -50%) !important;
    }
  }
}

@media screen and(min-width: 768px) {
  .rego-popup {
    .rego-popup-content {
      max-width: 480px;
    }
  }
}
</style>