var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShowRegoPopup)?_c('div',{staticClass:"rego-popup"},[_c('div',{staticClass:"rego-popup-content"},[_c('h3',[_vm._v("Update your rego")]),_c('p',{staticClass:"font-weight-thin"},[_vm._v(" We noticed that you didn't provide a registration number. Please enter your "+_vm._s(_vm.isShowStateField ? "'state' and " : "")+" 'registration number' if you have one. ")]),_c('p',{staticClass:"font-weight-thin"},[_vm._v(" If your car is unregistered click 'Skip rego' to continue. ")]),_c('div',{staticClass:"rego-popup-form mt-4"},[(_vm.isShowStateField)?_c('div',{staticClass:"state-input"},[_c('p',{staticClass:"font-weight-regular mb-2"},[_vm._v("State")]),_c('custom-select',{class:{
            error: _vm.$v.regoPopupInfo.state.$error,
            'shadow-none': true,
          },attrs:{"label":"","options":_vm.states,"error":_vm.$v.regoPopupInfo.state.$error,"helperText":"Please select state"},model:{value:(_vm.regoPopupInfo.state),callback:function ($$v) {_vm.$set(_vm.regoPopupInfo, "state", $$v)},expression:"regoPopupInfo.state"}})],1):_vm._e(),_c('div',{staticClass:"rego-input"},[_c('p',{staticClass:"font-weight-regular mb-2"},[_vm._v("Rego")]),_c('float-label',{attrs:{"label":""}},[_c('b-input',{class:{
              error: _vm.$v.regoPopupInfo.regoNumber.$error,
              'shadow-none': true,
            },attrs:{"placeholder":"Enter rego","autocomplete":"off","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.regoPopupInfo.regoNumber),callback:function ($$v) {_vm.$set(_vm.regoPopupInfo, "regoNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"regoPopupInfo.regoNumber"}})],1),(
            _vm.$v.regoPopupInfo.regoNumber.$dirty &&
            !_vm.$v.regoPopupInfo.regoNumber.required
          )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter rego number")])]):(
            _vm.$v.regoPopupInfo.regoNumber.$dirty &&
            !_vm.$v.regoPopupInfo.regoNumber.alphaNum
          )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter only alphabet characters and numerics")])]):(
            _vm.$v.regoPopupInfo.regoNumber.$dirty &&
            !_vm.$v.regoPopupInfo.regoNumber.maxLength
          )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter 1 to 9 characters!")])]):_vm._e()],1)]),_c('div',{staticClass:"rego-popup-buttons mt-4"},[_c('button',{staticClass:"rego-popup-skip",on:{"click":_vm.handleSkipRego}},[_vm._v("Skip rego")]),_c('button',{staticClass:"rego-popup-update",class:_vm.updateButtonDisable ? 'disabled' : '',on:{"click":_vm.handleUpdateRego}},[_c('b',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingApi),expression:"!isLoadingApi"}],staticClass:"font-weight-regular"},[_vm._v("Update")]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingApi),expression:"isLoadingApi"}],staticClass:"input-spinner",attrs:{"src":require("../../assets/images/white-input-spinner.gif")}})])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }